module.exports = {
  'siteMetadata.title': 'Lylli | Tausende Ebooks und Hörbücher für Kinder',
  'siteMetadata.description': 'Willkommen bei Lylli! Hier kannst du zwischen vielen Kinderbüchern auswählen, dir diese von einem Erzähler vorlesen lassen und selbst offline immer dabei haben.',
  'head.title': 'Lylli | Tausende Ebooks und Hörbücher für Kinder',

  'link.klarna': 'https://www.klarna.com/de/klarna-app/',
  'link.inviteFriend': '',
  'link.faq': 'https://www.lylli.de/fragen-und-antworten',
  'link.createAccount' : 'https://www.lylli.de/register/create-account',

  'logout': 'Ausloggen',

  'login.title': 'Einloggen',
  'login.email-label': 'Mail-Adresse',
  'login.email-placeholder': 'Gib deine Mail-Adresse an',
  'login.password-label': 'Dein passwort',
  'login.password-placeholder': 'Gib dein Passwort an',
  'login.submit':'Einloggen',
  'login.submit-error': 'Falsche E-Mail-Adresse oder Passwort.',
  'login.createAccount':'Konto anlegen',
  'login.resetPassword':'Passwort vergessen?',
  'login.loading': 'Einloggen...',

  'resetPassword.title':'Passwort vergessen',
  'resetPassword.text': 'Gib deine E-Mail-Adresse ein, um dein Passwort zurückzusetzen.',
  'resetPassword.email-placeholder': 'Gib deine Mail-Adresse an',
  'resetPassword.submit': 'Passwort zurücksetzen',
  'resetPassword.submit-confirmed':'Ein Wiederherstellungslink wurde an deine E-Mail gesendet.',

  'password.title' : 'Zurücksetzen des Passwortes',
  'password.text' : 'Du bist dabei, dein Passwort zu ändern.',
  'password.password-label': 'Wähle ein neues Passwort',
  'password.repeatPassword-label': 'Wiederhole dein neues Passwort',
  'password.submit': 'Ändere Passwort',
  'password.submit-confirmed': 'Dein Passwort wurde nun geändert.',
  'password.submit-errorLength': 'Das Password muss mindestens 8 Zeichen enthalten.',
  'password.submit-errorRepeat': 'Die Passwörter stimmen nicht überein.',

  'shareCode.notFound' : 'Der Code konnte nicht abgerufen werden, bitte versuche es erneut oder wende dich an kontakt@lylli.de',

  'profile.title': 'Dein Profil',
  'profile.welcome-header': 'Hallo!',
  'profile.welcome-subheader': 'Mein Konto',
  'profile.changeBtn': 'Ändern',

  'profile.subscription-header': 'Abonnement',
  'profile.subscription.type-header': 'Abowahl:',
  'profile.subscription.type-inactive': 'Noch nichts ausgewählt',
  'profile.subscription.type-notActivated': 'Nicht aktiviert',
  'profile.subscription.type-golden': 'Kostenlose Probezeit ohne registrierte Bezahlmethode',
  'profile.subscription.type-giftcard': 'Gutschein ohne angegebene Zahlungsmethode.',

  'profile.subscription.startSub': 'Abonnement starten',
  'profile.subscription.endSub' : (type) => {
    if(type === 'APPLE') {
      return 'Abmeldung über Apple';
    } else {
      return 'Abo kündigen';
    }
  },
  'profile.subscription.changeSub' : (type) => {
    if (type === 'APPLE') {
      return 'Abo via Apple aktualisieren';
    } else {
      return 'Abonnement aktualisieren';
    }
  },
  'profile.subscription.startTrial': 'Abo starten',
  'profile.subscription.addPayment' : 'Bezahlmethode hinzufügen',
  'profile.subscription.loading' : 'Information über Abonnement lädt...',
  'profile.subscription.active-offer-header': 'Aktuelles Angebot:',
  'profile.subscription.active-offer-text': (discount, totalPeriods, subscriptionType) => {
    let periodText;
    let periodicity;

    if (totalPeriods === 1 || (totalPeriods === 12 && subscriptionType === 'YEARLY')) periodText = 'im ersten';
    else if (totalPeriods === 2) periodText = 'in den ersten zwei';
    else periodText = `in den ersten ${totalPeriods}`;

    if (subscriptionType === 'MONTHLY') periodicity = 'Monat' + (totalPeriods !== 1 ? 'en' : '');
    else if (subscriptionType === 'YEARLY') periodicity = 'Jahr' + (totalPeriods !== 12 ? 'en' : '');
    else if (subscriptionType === 'QUARTERLY') periodicity = 'Quartal' + (totalPeriods !== 3 ? 'en' : '');
    else periodicity = subscriptionType;

    return `${discount} Rabatt ${periodText} ${periodicity}`;
  },
  'profile.subscription.offer-header' : 'Angebot:',
  'profile.subscription.offer-firstMonth': 'im 1.',
  'profile.subscription.addPayment-reactivate': 'Füge eine Zahlungsmethode hinzu und reaktiviere dein Konto für ',
  'profile.subscription.addPayment-freeTrial': {
    text1: 'Füge eine Zahlungsmethode hinzu und lies mit Lylli kostenlos für ',
    text2: ' Tage.'
  },
  'profile.subscription.activeDays-header': 'Benutze Lylli weiterhin für:',
  'profile.subscription.nextPayment-header': 'Nächster Abbuchungstag:',
  'profile.subscription.trialPeriod-header': 'Probezeit:',
  'profile.subscription.trialPeriod-days': (days) => days === 1 ? `Noch ${days} Tage`: `Noch ${days} Tage`,
  'profile.subscription.grace-addPayment': 'Wir konnten keine Abbuchung für dein Abo vornehmen. Bitte aktualisiere deine Bezahlmethode, um Lylli weiterhin nutzen zu können.',
  'profile.subscription.golden-addPayment': (date) => `Registrera en betalmetod senast ${date} för att fortsätta läsa med Lylli.`,
  'profile.subscription.giftcard-addPayment': (date) => `Füge bis spätestens zum ${date} eine Zahlungsmethode hinzu, um nach Ablauf des Gutscheins mit Lylli weiterzulesen.`,
  'profile.subscription.validUntil-header': 'Gültig bis:',

  'cancelSub-title': (step, periodCount, price, subscriptionType) => {
    if (step === 1) return 'Bevor du dein Abo kündigst ...';
    if (step === 2) return 'Bist du dir sicher, dass du kündigen möchtest?';
    if (step === 3) {
      if(periodCount && price && subscriptionType) {
        const plural = periodCount !== 1;
        let periodicity;
        if (subscriptionType === 'MONTHLY') periodicity = 'Monat' + (plural ? 'en' : '');
        else if (subscriptionType === 'YEARLY') periodicity = 'Jahr' + (plural ? 'en' : '');
        else periodicity = subscriptionType;    

        if(subscriptionType === 'MONTHLY') return `Angebot: ${periodCount} ${periodicity} für ${price} €`;
        if(subscriptionType === 'YEARLY') return '';
      } else {
        return '';
      }
    };
    if (step === 4) return 'Warum möchtest du dein Abo kündigen?';
    if (step === 5) return 'Dein Abo wurde gekündigt 😢';
  },

  'cancelSub-subtitle': (step) => {
    if (step === 1) return null;
    if (step === 2) return null;
    if (step === 3) return 'Letzte Gelegenheit';
    if (step === 4) return 'Abonnement kündigen';
    if (step === 5) return null;
  },

  'cancelSub-secondaryCTA': (step) => {
    if (step === 1) return 'Kündigen';
    if (step === 2) return 'Trotzdem kündigen';
    if (step === 3) return 'Nein, danke';
    if (step === 4) return 'Abonnement verlängern';
    if (step === 5) return 'Feedback schicken';
  },

  'cancelSub-primaryCTA': (step) => {
    if (step === 1) return 'Bleib und schick es an den Support';
    if (step === 2) return 'Meinen Preis behalten';
    if (step === 3) return 'Angebot annehmen';
    if (step === 4) return 'Kündigen';
    if (step === 5) return 'Schließen';
  },

  'cancelSub.contactSupport': {
    text1: 'Gibt es etwas, das dich stört oder bei dem wir dir helfen können?',
    text2: 'Schreibe uns gerne eine Nachricht in das untenstehende Feld, dann hilft dir unser Support-Team schnellstmöglich weiter. Vielleicht kannst du Lylli so weiterhin entdecken?'
  },

  'cancelSub.contactSupport-CTA': 'Schreib deine Nachricht ins obenstehende Feld, dann melden wir uns schnellstmöglich bei dir!',

  'cancelSub.currentDiscount-foreverDiscount': (subscriptionType, currentPrice, defaultPrice) => {
    if(subscriptionType === 'MONTHLY') return `Im Moment zahlst du ${currentPrice} €/Monat – ganze ${defaultPrice - currentPrice} € weniger als unser aktueller Monatspreis von ${defaultPrice} €.`;
    if (subscriptionType === 'YEARLY') {
      const monthlyPrice = (currentPrice / 12).toFixed(1);
      return `Im Moment zahlst du ${monthlyPrice} €/Monat (${currentPrice} €/Jahr) – ganze ${defaultPrice - currentPrice} € weniger als unser aktueller Jahrpreis von ${defaultPrice} €.`;
    } 
  },

  'cancelSub.currentDiscount-nextPaymentDiscount': (subscriptionType, currentPrice, defaultPrice) => {
    if(subscriptionType === 'MONTHLY') return `Im Moment zahlst du nur ${currentPrice} € für den nächsten Monat (Normalpreis ${defaultPrice}) €/Monat).`;
    if(subscriptionType === 'YEARLY') return `Im Moment zahlst du nur ${currentPrice} € für das nächste Jahr (Normalpreis ${defaultPrice} €/Jahr).`; 
  },

  'cancelSub.currentDiscount-percentageOf': (percentage, subscriptionType) => {
    if(subscriptionType === 'MONTHLY') return `Momentan bekommst du einen Rabatt von ${percentage}% auf dein Abo für den Folgemonat.`
    if(subscriptionType === 'YEARLY') return `Momentan bekommst du einen Rabatt von ${percentage}% auf dein Abo für den Folgemonat.`
  },

  'cancelSub.currentDiscount-text' : 'Wenn du dein Konto kündigst, verlierst du diesen Rabatt und kannst diesen nicht länger einlösen. Bist du dir sicher, dass du trotzdem kündigen möchtest?',

  'cancelSub.cancellationOffer': (subscriptionType, periodCount, price) => {
    let periodicity;

    if (subscriptionType === 'MONTHLY') periodicity = 'Monat' + (periodCount !== 1 ? 'en' : '');
    else if (subscriptionType === 'YEARLY') periodicity = 'Jahr';
    else periodicity = subscriptionType;    

    return `Lies und lausche ${periodCount} ${periodicity} lang unbegrenzt für nur ${price} €.`
  },

  'cancelSub.cancellationOffer-yearly': (price) => {
    return `Abonniere ein Jahresabo bei Lylli für nur ${(price/12).toFixed(2)} €/Monat (${price} €/Jahr) und bekomme ein Kuscheltier (Wert: 20 €) direkt zu dir nach Hause geschickt.`
  },

  'cancelSub.cancellationOffer-text': 'Natürlich schicken wir dir eine Erinnerung, bevor der Zeitraum endet.',

  'cancelSub.chooseReason' : 'Bitte gib einen Grund an, um dein Abo zu kündigen!',
  
  'cancelSub.endSub-activeUntil' : (date) => {
    return `Du kannst Lylli noch bis ${date} nutzen.`;
  },
  'cancelSub.feedback' : {
    text1: 'Gibt es etwas, das wir verbessern können?',
    text2: 'Wir freuen uns über jegliches Feedback!'
  },
  
  'cancelSub.confirmation' : {
    text1: 'Hoffentlich sehen wir uns bald wieder ',
    text2: 'Dein Lylli-Team'
  },

  'cancelSub.reasons' : [
    { id: 'content-not-good-enough', label: 'Die Bücher, die ich lesen/ anhören möchte, gibt es nicht.'},
    { id: 'reduce-costs', label: 'Die Kosten sind zu hoch.' },
    { id: 'had-technical-issues', label: 'Ich habe Probleme mit der App.' },
    { id: 'try-other-service', label: 'Ich probiere anstelle eine andere App aus.' },
    { id: 'only-wanted-to-test', label: 'Ich möchte nur am Angebot teilnehmen.' },
    { id: 'below-expectations', label: 'Die App entspricht nicht meinen Vorstellungen.' },
    { id: 'others-no-reason', label: 'Anderer Grund' },
  ],

  'changeSub.updatedSub': (type) => {
    if(type === '1') {
      return 'Dein Abonnement wurde jetzt umgestellt zu einem Monatsabonnement.';
    } else {
      return 'Dein Abonnement wurde jetzt umgestellt zu einem Jahresabonnement.';
    }
  },
  'changeSub.tipCTA': {
    text1: '',
    text2: 'Wechsle zu einem Jahresabonnement und spare 27%.'
  },
  'changeSub.savings': 'Du sparst 27%',

  'profile.payment-header': 'Bezahlung',
  'profile.payment.type-header': 'Bezahlmethode:',
  'profile.payment.type-missing': 'Fehlt',
  'profile.payment.type-card': 'Kreditkarte',
  'profile.payment.type-apple_pay': 'Apple Pay',
  'profile.payment.type-google_pay': 'Google Pay',
  'profile.payment.type-paypal': 'PayPal',
  'profile.payment.type-invoice': 'Klarna',
  'profile.payment.type-direct_debit': 'Klarna',
  'profile.payment.type-in_app_purchase': 'Apple abonnement',
  'profile.payment.type-unknown': 'Aktiv',

  'profile.account-header': 'Konto',
  'profile.account.accountSince-header': 'Kunde seit:',
  'profile.account.email-header': 'E-Mail-Adresse:',
  'profile.account.password-header': 'Passwort:',

  'profile.payment-history-header': 'Bestellhistorie',
  'profile.payment-history-subscription': 'Abo',
  'profile.receipts-header': 'Quittungen',
  'profile.receipts-empty': 'Keine Quittungen',
  'profile.receipt-header': 'Quittung',
  'profile.receipt-seller': 'Verkäufer: ',
  'profile.receipt-seller-organisation': 'Organisationsnummer: ',
  'profile.receipt-seller-adress': 'Adresse: ',
  'profile.receipt-date': 'Datum: ',
  'profile.receipt-amount': 'Betrag: ',
  'profile.receipt-subtotal-amount': 'Exkl. MwSt.: ',
  'profile.receipt-tax-amount': 'MwSt.: ',
  'profile.receipt-items': 'Artikel',
  'profile.receipt-item': 'St.',
  'profile.receipt-pdf': 'PDF herunterladen',
  'profile.receipts-error': 'Beim Herunterladen der Quittung ist ein Fehler aufgetreten',

  'profile.download-header': 'Lylli downloaden',
  'profile.download-text': 'Lylli gibt es für iOS und Android. Die App kann auf dem Handy und Tablet benutzt werden.',

  'profile.help-header': 'Brauchst du Hilfe?',
  'profile.help.faq': 'FAQ',
  'profile.help.support': 'Kontakt',

  'inviteFriend-header': 'Lade einen Freund ein!',
  'inviteFriend-text': 'Lade einen Freund ein, damit dein Freund Lylli 30 Tage lang ausprobieren kann! Teile den untenstehenden Code.',
  'inviteFriend-textCopied': (copy) => {
    if(copy) return 'Kopiert!'
    return'Kopieren'
  },

  'apple-step1': '1. Öffne “Einstellungen” auf deinem Gerät.',
  'apple-step2': '2. Klicke auf deinen Namen.',
  'apple-step3': '3. Klicke auf “Abonnements”.',
  'apple-step4': '4. Klicke auf Lylli.',

  'apple.cancelSubscription-header': 'So kündigst du dein Abo:',
  'apple.cancelSubscription-step5': '5. Klicke auf “Abo kündigen”.',

  'apple.handleSubscription-header': 'So wechselst du dein Abo:',
  'apple.handleSubscription-step5': '5. Wähle ein Abonnement.',

  'apple.changeSubscription-header': 'Du hast Apple als Bezahlmethode angegeben',
  'apple.changeSubscription-text1': 'Um deine Bezahlmethode ändern zu können, musst du zunächst dein Lylli-Abo bei Apple kündigen. Dann kannst dein Abo hier via “Mein Konto” aktualisieren und eine neue Bezahlmethode hinzufügen.',
  'apple.changeSubscription-text2' : 'Lylli-Abo bei Apple kündigen:',

  'apple.getSupport': 'Brauchst du Hilfe? Schreibe uns eine Mail an ',

  'apple.receipt-appstore': 'via App Store',
  'apple.receipt-header': 'So findest du die Rechnungsbelege für deine Käufe im App Store',
  'apple.receipt-step3': '3. Klicke auf “Medien & Käufe“ und dann “Account anzeigen“.',
  'apple.receipt-step4': '4. Klicke auf “Einkaufsstatistik“ och suche nach Lylli.',

  'receipts.klarna' : {
    text1: 'Logge dich in die ',
    linkText: 'Klarna-app',
    text2: ' ein um deine Quittungen zu sehen.'
  },

  'receipts.contactSupport': {
    text1: 'Kontaktiere unseren Kundensupport via ',
    text2: ' um deine Quittungen zu sehen.'
  },

  'giftcard': {
    text1: 'Du möchtest deinen Geschenkegutschein einlösen, yay! Kontaktiere unseren Kundensupport via ',
    text2: ', dann helfen wir dir dabei.'
  },

  'modido.changeCard-header': 'Verwalte deine Bezahlung ',
  'modido.changeCard-text': 'Hier fügst du die Karte hinzu, mit der du Lylli abonnieren möchtest.',

  'accountUpdated': 'Konto aktualisiert',

  'subscription.activeInDays': (days) => `${days} Tag${days === 1 ? '' : 'e'}`,

  'base.save': 'Speichern',
  'base.close': 'Schließen',

  'changeEmail.title' : 'Mail-Adresse ändern',
  'changeEmail.confirmPassword-label': 'Trage dein Passwort ein',
  'changeEmail.confirmPassword-placeholder': 'Dein Passwort',
  'changeEmail.email-label' : "Neue Mail-Adresse",
  'changeEmail.email-placeholder' : 'Mail-Adresse',
  'changeEmail.confirmEmail-label': 'Bestätige neue Mail-Adresse',
  'changeEmail.error.incorrectEmail': 'Ungültige Mail-Adresse ',
  'changeEmail.error.noMatch' : 'Die Mail-Adresse scheint nicht korrekt zu sein',

  'changePassword.title' : 'Passwort ändern',
  'changePassword.oldPassword-label': 'Trage dein jetziges Passwort ein',
  'changePassword.oldPassword-placeholder': 'Dein altes Passwort',
  'changePassword.newPassword-label': 'Trage dein neues Passwort ein:',
  'changePassword.newPassword-placeholder': 'Dein neues Passwort',
  'changePassword.confirmPassword-label': 'Bestätige dein neues Passwort',
  'changePassword.confirmPassword-placeholder': 'Wiederhole neues Passwort',
  'changePassword.error.oldPassword': 'Du musst dein altes Passwort eintragen',
  'changePassword.error.tooShort': 'Das Passwort sollte aus mindestens 8 Zeichen bestehen',
  'changePassword.error.noMatch': 'Passwörter stimmen nicht überein',

  'date-format': 'de-DE',
}
